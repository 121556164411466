@import "../../styles/variables/_colors.scss";
@import "../../styles/theme.scss";

$font-size: 13px;

html,
body {
  margin: 0;
  background-color: var(--background-color);
  color: var(--text-color);
  font-size: 13px;
}

* {
  font-family: "Open Sans", sans-serif, Arial;
}

body {
  direction: var(--interface-direction);
}
